import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'

import Check from './Check'

render(<BrowserRouter>
	<div>
		<Route path="/" component={Check} />
	</div>
</BrowserRouter>, document.getElementById('root'))

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    let swPath = 'service-worker.js';
    navigator.serviceWorker.register(swPath).then(function(registration) {
      // Registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}