import React from 'react'
import Layout from './Layout'

export default class Check extends React.Component {

	state = {
	    online: !!navigator.onLine
	}

	componentDidMount() {
	    setInterval(() => this.setState({ online: !!navigator.onLine }), 500)
	}

	render() {
	    if (!this.state.online) {
    
		return (
			<Layout>
				<div className="text-center">
					<h3>Niestety, nie posiadasz dostepu do internetu! :(</h3>
					<p>Sprawdz swoje polaczenie z internetem.</p>
				</div>
			</Layout>
		)
	    }

		return (
			<Layout>
				<div className="text-center">
					<h3>Hurra! Masz polaczenie z internetem.</h3>
					<p>Strona dziala w trybie On-line!.</p>
				</div>
			</Layout>
		)
	}
}