import React from 'react'

export default class Layout extends React.Component {
	render() {
		return (
			<div>
				<div className="heading">
					<div className="container">
						<h2 className="mb-3">
						    Test PWA / ServiceWorker
						</h2>
					</div>
				</div>
				<div className="layout">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								{this.props.children}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}